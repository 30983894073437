
body {
  margin: 0;
  /* font-family:"Charlie Display", "poppins",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  /* font-family: "poppins",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: "urbanist",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-weight: 500;
  transition: 0.1s ease;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#hubspot-messages-iframe-container {
  color-scheme : light;
}

html[data-color-mode="dark"] {
  --ds-surface: #101118 !important;
}



@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Thin.woff2);
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Regular.woff2);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Semibold.woff2);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Bold.woff2);
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Thin_Italic.woff2);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Italic.woff2);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Semibold_Italic.woff2);
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Charlie Display";
  src: url(./fonts/Charlie_Text_Font_Family/Charlie_Text-Bold_Italic.woff2);
  font-weight: 700;
  font-style: italic;
}

.Typewriter__cursor {
  font-size: 3rem !important;
}
.sign-in-cursor {
  font-size: 1.5rem !important;
}


.header-navbar-brand-light{
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 700;
  height: 40px;
  font-family: poppins;
  color: black;
}

@media (max-width: 768px) {
  .header-navbar-brand-light{
    font-size: 1.1rem;
  }
}


.header-navbar-brand-light:hover {
  color: #dfdcd8;
}

.header-navbar-brand-dark{
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 700;
  height: 40px;
  font-family: poppins;
  color: white;
}

@media (max-width: 768px) {
  .header-navbar-brand-dark{
    font-size: 1.1rem;
  }
}


.header-navbar-brand-dark:hover {
  color: #dfdcd8;
}

.header-navbar-light {
  border: 2px solid #dfdcd8;
  background-color: white;
  color: rgba(0,0,0,.55);
  cursor: pointer;
  font-weight: 500;
}
.header-navbar-dark {
  /* border-bottom: 2px solid #101118; */
  background-color: rgb(36, 34, 43, 0.6);
  backdrop-filter: blur( 6.5px );
  color: white;
  font-weight: 500;

}
.header-navbar-transparent {
  background-color: transparent;
  color: white;
  font-weight: 500;

}


.header-navlink-light {
  color: rgba(0,0,0,.55) !important;
  border: none;
}
.header-navlink-dark {
  color: white !important;
}

.settings-link-light {
  color: rgba(0,0,0 ) !important;
}
.settings-link-dark {
  color: white !important;
}

.chat-navbar-light {
  border: 1px solid #dfdcd8 !important;
}

.chat-navbar-dark {
  border: 1px solid #101118 !important;
}

.custom-navbar-light {
  position: fixed; /* Keep the sidebar fixed */
  top: 0;
  bottom: 0;
  min-width: 250px; /* Set the sidebar's width */
  overflow-y: auto; /* Allow scrolling within the sidebar */
  transition: width 0.3s ease;
  margin-top: 50px;
  border: 1px solid #dfdcd8;
  background-color: white;
  color: black;
}

.custom-navbar-dark {
  position: fixed; /* Keep the sidebar fixed */
  top: 0;
  bottom: 0;
  min-width: 250px; /* Set the sidebar's width */
  overflow-y: auto; /* Allow scrolling within the sidebar */
  transition: width 0.3s ease;
  margin-top: 50px;
  border: 1px solid #24222B;
  background-color: #24222B;
  color: white;
}

.custom-navbar-dark.collapsed {
  min-width: 80px;
}
.custom-navbar-light.collapsed {
  min-width: 80px;
}

.custom-navbar-outer {
  min-width: 250px;
}
.custom-navbar-outer.collapsed {
  min-width: 80px;
}

.navbar-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;
  position: sticky; 
  bottom: 0;
  width: 100%;
}

.navbar-items {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  /* font-family: 'Open Sans'; */
}

.navbar-items li {
  padding: 4px 4px;
  display: flex;
  align-items: center;
}

.navbar-items li span.title {
  margin-left: 10px;
}

.side-nav-button-add-chat {
  padding: 10px 25px;
  color: inherit;
  text-decoration: none;
  width: 100%;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid rgb(128, 128, 128, 0.3);
}

.side-nav-button-add-chat:hover {
  background-color: #dfdcd8;
  color: black;
}

.side-nav-button-add-chat.active {
  color: white;
  font-weight: 600;
  background-color: #0d6efd !important;
}

.side-nav-button {
  padding: 10px 25px;
  color: inherit;
  text-decoration: none;
  width: 100%;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.side-nav-button:hover {
  background-color: #dfdcd8;
  color: black;
}

.side-nav-button.active {
  color: white;
  font-weight: 600;
  background-color: #0d6efd !important;
}

.side-nav-text {
  padding-left: 10px;
  overflow-x: hidden;
  transition: 0.4s ease;
}

.side-nav-title-light {
  /* font-family: 'Open Sans'; */
  font-size: 12px;
  opacity: 0.7;
  color: #333;
  font-weight: 700;
  justify-content: center;
  padding: 10px;
  margin-left: 20px;
}

.side-nav-title-dark {
  /* font-family: 'Open Sans'; */
  font-size: 12px;
  opacity: 0.7;
  color: white;
  font-weight: 700;
  justify-content: center;
  padding: 10px;
  margin-left: 20px;

}

.base-page {
  padding: 20px; 
  width: 100%; 
  transition: width 0.3s ease;
  height: 100%;
}
/* 
.atlassian-select-box {
  z-index: 3002 !important;
} */


.Toastify__toast-container {
  z-index: 1000000; /* Adjust the value as needed */
}

/* Adjust the z-index of the modal */
.ak-modal-dialog {
  z-index: 99; /* Adjust the value as needed */
}

.modal-content {
  border: none;
}

.modal-content .form-group {
  padding-bottom: 10px;
}

.select-input-dark {
  background-color: #24222B;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.text-color-dark {
  color: "white"
}

.text-color-light {
  color: "black"
}

.swipe-left {
  animation: swipeLeft 0.4s forwards; /* Animation duration is 0.5s */
}

@keyframes swipeLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
#map-container {
  position: relative;
  height: 180px;
  width: 600px;
}

#map {
  position: relative;
  height: inherit;
  width: inherit;
}

.custom-marker {
  /* background-color: black; */
  border-radius: 5px;
  color: black;
  font-size: 12px;
  text-align: center;
  /* Add more styles as needed */
}

.mapboxgl-popup-content {
  color:  black;
}

.centeredModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-text {
  /* background: linear-gradient(45deg, #8C73EE, #3813D1); */
  background: linear-gradient(45deg, #71d6fe, #0c99d0 50%, #71d6fe 100%);

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* Fallback: set a background color. */
  font-size: 48px;
  font-weight: 600;
}

.tbwe-text {
  background:#3AAFFB;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* Fallback: set a background color. */
  font-size: 48px;
  font-weight: 600;
}

.highlight-row {
  background-color: #1B1F27 !important; /* Or any color that signifies highlighting */
}

.button-primary-1-dark {
  background-color: white !important;
  transition-delay: 250ms;
  color: black;
  border-radius: 20px !important;
  font-size: 14px !important;
}

.button-primary-1-dark:hover {
  background-color: rgb(192, 192, 192) !important;

}

.homepage-title {
  font-size: 3rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  .homepage-title {
    max-width: 75%;
  }
}

.homepage-subtitle {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .homepage-subtitle {
    max-width: 75%;
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}



.map-region-data-column-1 {
 padding: 10px;
  color: white;
}

.map-region-data-column-2 {
  background-color: #24222B;
  padding: 10px;
  color: white;
}


.partners-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.partner-item {
  width: 150px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-item img {
  max-width: 100%;
  height: auto;
}


/* Container for the two tiles */
.homepage-info-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px;
  
}

/* Shared tile styles */
.tile {
  background-color: #24222B;
  margin: 0px;
  margin: 10px;
  border-radius: 20px;
  
  color: white;
}

/* Larger tile - takes 3/5 width */
.tile-large {
  flex-grow: 3;
  flex-basis: 60%;
  background-image: url(https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Misc/pngLines30.png);
}

/* Smaller tile - takes the remaining 2/5 width */
.tile-small {
  flex-grow: 2;
  flex-basis: 35%;
}

/* Stacking on mobile screens */
@media (max-width: 768px) {
  .tile-large, .tile-small {
    flex-basis: 100%;
  }
}


/* Footer container */
.footer {
  background-color: #101118;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

/* Footer links */
.footer-links {
  display: flex;
  justify-content: center;
}

.footer-link {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Footer copyright */
.footer-copyright {
  margin: 10px 0;
}

/* Social media icons */
.footer-social {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icon img {
  width: 30px;
  height: 30px;
}

/* Stacking elements on small screens */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }

  .footer-links {
    margin-bottom: 10px;
  }

  .footer-social {
    margin-top: 10px;
  }
}

.team-members {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on large screens */
  gap: 20px;
  padding: 0 20px;
  max-width: 1200px;
}

@media (max-width: 1024px) {
  .team-members {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .team-members {
    grid-template-columns: 1fr; /* 1 column on mobile screens */
  }
}


.team-tile {
  background-color: #23222B;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.contact-icon {
  color: #003366;
  text-decoration: none;
  font-size: 1rem;
}

.contact-icon:hover {
  color: #0056b3;
  text-decoration: underline;
}

.m-scroll {
  display: flex;
  position: relative;
  width: 1000px; /* Adjust width as needed */
  max-width: 1000px;
  height: 100px; /* Adjust height as needed */
  margin: auto;
  background-color: #101118;
  overflow: hidden;
  z-index: 1;
}

.m-scroll__title {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transform: scale(1.2); /* Adjust scaling */
}

.m-scroll__title > div {
  display: flex;
  animation: scrollText 25s infinite linear;
}

.m-scroll__title h1 {
  margin: 0;
  font-size: 40px; /* Adjust font size as needed */
  color: rgba(255, 255, 255, 1);
}

.m-scroll__title a {
  text-decoration: none;
  color: white;
}

.m-scroll__title a:hover {
  -webkit-text-stroke: 1px white;
  color: transparent;
}

/* Keyframes for scrolling effect */
@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}